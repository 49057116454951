import React, {useEffect} from 'react'
import { createSelector } from "reselect"
import { useSelector, useDispatch } from "react-redux"

const selectBenchmarkState = state => state.teacher.benchmark
const selectProperties = createSelector([selectBenchmarkState], state => ({
  isModal: state.isModal,
  mode: state.mode,
  isModalDelete: state.isModalDelete,
}))



const Benchmark = () => {
     const dispatch = useDispatch()
     const benchmarkState = useSelector(selectProperties)
     const { isModal, mode, isModalDelete } = benchmarkState
     
console.log("benchmarkState", benchmarkState)
  useEffect(() => {
 
      dispatch(
        onReadData({})
        
        
      )
    
  }, [dispatch])





  return (
    <div>Benchmark</div>
  )
}

export default Benchmark