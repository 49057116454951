import React from 'react'
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap"
import { Link } from 'react-router-dom'
const SubjectCardStudent = props => {


  const {
    name,
    id,
    image,
    teacher_name,
    pending_assignments,
    unread_annoucements,
    teacher_image
  } = props.data

  return (
    <React.Fragment>
      <Card key={id} className="ms-1" style={{ width: "12.5rem" }}>
        <Link
          to={`/subjects/${id}/start`}
          // onClick={() => dispatch(selectedSubject(report, "1"))}
          className="waves-effect"
        >
          <img src={image} className="card-img-top rounded-top" alt={name} />
          <CardBody className="p-0 ">
            <CardTitle className="mt-0">{name}</CardTitle>
          </CardBody>
        </Link>
        <div className="d-flex justify-content-between ">
          <div
            className="btn  noti-icon waves-effect"
            tag="button"
            id={"Tareas_" + id}
          >
            {pending_assignments === 0 ? (
              <i className="bx bx-book"></i>
            ) : (
              <>
                <i className="bx bx-book bx-tada "></i>
                <span
                  className="badge badge-danger badge-pill "
                  // style={{ width: "12rem" }}
                >
                  {pending_assignments}
                </span>
              </>
            )}
            <UncontrolledTooltip placement="top" target={"Tareas_" + id}>
              Tareas
            </UncontrolledTooltip>
          </div>
          <div
            className="btn  noti-icon waves-effect "
            tag="button"
            id={"Announcements_" + id}
          >
            {unread_annoucements === 0 ? (
              <i className="bx bxs-megaphone"></i>
            ) : (
              <>
                <i className="bx bxs-megaphone bx-tada"></i>
                <span
                  className="badge badge-danger badge-pill"
                  // style={{ width: "12rem" }}
                >
                  {unread_annoucements}
                </span>
              </>
            )}
            <UncontrolledTooltip placement="top" target={"Announcements_" + id}>
              Anuncios
            </UncontrolledTooltip>
          </div>
          <div className="text-sm-right mt-4 mt-sm-0">
            <img
              className="rounded-circle header-profile-user"
              src={teacher_image}
              alt="Header Avatar"
            />
            <p className="text-muted mb-0 pr-2">{teacher_name}</p>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default SubjectCardStudent