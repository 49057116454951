
import CoordinatorMiddleware from "middleware/CoordinatorMiddleware";
import Inbox from "pages/CommonPages/Inbox/Inbox";
import Profile from "pages/CommonPages/Profile.js/Profile";
import RemoteSupport from "pages/CommonPages/RemoteSupport/RemoteSupport";
import Settings from "pages/CommonPages/Settings/Settings";
import Assignments from "pages/CoordinatorPages/Assignments/Assignments";
import CoordinatorDashboard from "pages/CoordinatorPages/CoordinatorDashboard/CoordinatorDashboard";
import Grades from "pages/CoordinatorPages/Grades/Grades";
import Plannings from "pages/CoordinatorPages/Plannings/Plannings";
import SchoolGrade from "pages/CoordinatorPages/SchoolGrade/SchoolGrade";
import Subjects from "pages/CoordinatorPages/Subjects/Subjects";
import Tutorials from "pages/CoordinatorPages/Tutorials/Tutorials";
import Students from "pages/CoordinatorPages/Users/Students";
import Teacher from "pages/CoordinatorPages/Users/Teacher";
import User from "pages/CoordinatorPages/Users/User";
import VirtualClassrooms from "pages/CoordinatorPages/VirtualClassroom/VirtualClassrooms";
import { Route } from "react-router-dom"


export default [
  <Route element={<CoordinatorMiddleware />}>
    <Route path="/dashboard" element={<CoordinatorDashboard />} />
    <Route path="/users" element={<User />}>
      <Route path="teachers" element={<Teacher />} />
      <Route path="students" element={<Students />} />
    </Route>
    <Route path="/grades" element={<Grades />} />
    <Route path="/subjects" element={<Subjects />} />
    <Route path="/assigments" element={<Assignments />} />
    <Route path="/school-grades" element={<SchoolGrade />} />
    <Route path="/plannings" element={<Plannings />} />
    <Route path="/inbox" element={<Inbox />} />
    <Route path="/virtual-classroom" element={<VirtualClassrooms />} />
    <Route path="/tutorials" element={<Tutorials />} />
    <Route path="/remote-support" element={<RemoteSupport />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/settings" element={<Settings />} />
  </Route>,
] 
