import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import { onReadData, onReadDataSuccess, onEdit } from "./academicStandarSlice"
import { toast } from "react-toastify"
import { generateOptions } from "helpers/select_helper"

function* workerFetch({ payload: { id }}) {
  try {
    console.log("workerFetch")
    const response = yield call(
      apiClient.get,
      `/api/teacher/${id}/subjects`
    )
    console.log(response.data)
    const options=generateOptions(response.data, "name", "id")
    yield put(onReadDataSuccess({subjects: response.data,options}))
    
  } catch (error) {
    toast.error(error.message)
  }
}


export function* watchAcadmicStandar() {
  yield takeLatest(onReadData.type, workerFetch)
//  yield takeLatest(onEdit.type, workerEditGradeBook)
}

export default watchAcadmicStandar
