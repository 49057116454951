


export const coordinatorEndpoints = {
  create: "/api/coordinators",
  read: id => `/api/coordinators/${id}`,
  update: id => `/api/coordinators/${id}/update`,
  delete: id => `/api/coordinators/${id}`,
  subjects: id => `/api/coordinators/${id}/subjects`,
  dashboard: id => `/api/coordinators/${id}/dashboard`,
}
export const teacherEndpoints = {
  create: "/api/teachers",
  read: id => `/api/teachers/${id}`,
  update: id => `/api/teachers/${id}/update`,
  delete: id => `/api/teachers/${id}`,
  dashboard: id => `/api/teachers/${id}/dashboard`,
}

export const subjectEndpoints = {
  create: "/api/subjects",
  read: id => `/api/subjects/${id}`,
  update: id => `/api/subjects/${id}/update`,
  delete: id => `/api/subjects/${id}`,
  actvities: id => `/api/subjects/${id}/activities`,
  assignments: (id, query) => `/api/subjects/${id}/assignments?${query}`,
  competences: id => `/api/subjects/${id}/competences`,
  specific_competences: id => `/api/subjects/${id}/specific-competences`,
  gradebook: id => `/api/subjects/${id}/gradebook`,
  gradebookUpdate: id => `/api/subjects/${id}/gradebook`,
}


export const gradeEndpoints = {
  create: "/api/grades",
  read: id => `/api/grades/${id}`,
  update: id => `/api/grades/${id}/update`,
  delete: id => `/api/grades/${id}`,
  dashboard: id => `/api/grades/${id}/dashboard`,
  periods: id => `/api/grades/${id}/periods`,
}

export const studentEndpoints = {
  create: "/api/students",
  read: id => `/api/students/${id}`,
  update: id => `/api/students/${id}/update`,
  delete: id => `/api/students/${id}`,
  dashboard: id => `/api/students/${id}/dashboard`,
}
export const assignmentEndpoints = {
  create: "/api/assignments",
  read: id => `/api/assignments/${id}`,
  update: id => `/api/assignments/${id}/update`,
  delete: id => `/api/assignments/${id}`,
  show: id => `/api/assignments/${id}`,
}
export const competenceEndpoints = {
  create: "/api/competences",
  read: id => `/api/competences/${id}`,
  update: id => `/api/competences/${id}/update`,
  delete: id => `/api/competences/${id}`,
  indicators: id => `/api/competences/${id}/indicators`,
}
