import { all, fork } from "redux-saga/effects"
import dashboarSaga from "./dashboard/dashboardSaga"
import watchTeacherAssignments from "./subject/assignment/assignmentSaga"
import watchTeacherSubject from "./subject/subjectSaga"
import watchTeacherAnnouncements from "./subject/announcement/announcementSaga"
import watchTeacherSchoolGrade from "./subject/schoolGrade/schoolGradeSaga"
import watchAcadmicStandar from "./academicStandars/academicStandarSaga"


export default function* teacherSagas() {
  yield all([fork(dashboarSaga)])
  yield all([fork(watchTeacherAssignments)])
  yield all([fork(watchTeacherSubject)])
  yield all([fork(watchTeacherAnnouncements)])
  yield all([fork(watchTeacherSchoolGrade)])
  yield all([fork(watchAcadmicStandar)])

}
