import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";

 
import { get, post } from "../../../helpers/api_helper"
import {
  onLoginError,
  onLoginSuccess,
  onLoginUser,
  onLogoutUser,
  refreshTokenRequested,
  refreshTokenSuccess,
  refreshTokenFailure,
} from "./loginSlice"
import jwt_decode from "jwt-decode"
import { apiClient } from "../../../helpers/api_helper"
function* workerloginUser({ payload: { user, navigate } }) {
  try {
   
    const data = {
      username: user.email,
      password: user.password
    }
     const response = yield call(apiClient.post, '/api/login',data);
   // const response = yield post("/api/login", data)
     const token = response?.data?.token
   if (!token) {
     throw new Error("An error occurred")
   }
   
    const LoggedUser= jwt_decode(token);
    
     yield put(onLoginSuccess({LoggedUser,token})); 
  // yield delay(1000);
    // localStorage.setItem("authUser", JSON.stringify(response))
     navigate("/dashboard")
  } catch (error) {
console.log(error)
    if (error.isAxiosError ) {
     
    if (error.response) {
      console.log("hit ")
    const { status, data } = error.response
    if(status===401){
     yield put(onLoginError(data.messages.error)); 
    }else{
      yield put(onLoginError("An error occurred"));
    }
    

    }else{
      
       yield put(onLoginError(error.message));
    }
  
    }
    
    else{
     
        yield put(onLoginError("An error occurred"));
    }
   
    
   
  

  }
}

function* workerLogoutUser({ payload: { navigate } }) {
  try {
   

  const response = yield call(apiClient.get, '/api/logout');

    navigate("/login")
  } catch (error) {
    console.log(error)
    yield put(onLoginError(error))
  }
}


function* workerRefreshTokenSaga() {
  try {
    const response = yield call(apiClient.get, '/api/refresh-token',{
      withCredentials: true,
    });
    // Make a request to refresh the token
    const newAccessToken = response.data.token
    console.log(newAccessToken)
    yield put(refreshTokenSuccess(newAccessToken)) // Dispatch success action
  } catch (error) {
    console.log(error)
    yield put(refreshTokenFailure(error)) // Dispatch failure action
  }
}



function* authSaga() {
 
 // yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(onLoginUser.type, workerloginUser);
    yield takeEvery(onLogoutUser.type, workerLogoutUser);
    yield takeLatest(refreshTokenRequested.type, workerRefreshTokenSaga);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
 //yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
