import React from "react"
import {
  Table,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap"

const PrivilegeRemovalForm = (props) => {
 
  return (
    <Table className="table-striped">
      <tbody>
        <tr>
          <td colSpan={3}>
            <h3 className="text-center">Retiro de Privilegios</h3>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>Alumno</td>

          <td>Curso</td>
        </tr>
        <tr>
          <td>Fecha del Incidente</td>
          <td>Materia</td>
          <td>Profesor</td>
        </tr>
        <tr>
          <td colSpan={3}>Razones:</td>
        </tr>
        <tr>
          <td colSpan={3}>
            <Form>
              <Row className="row-cols-lg-auto g-3 align-items-left">
                <Col  xs = "4">
                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Tardanza a clase</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Molestando a los compañeros</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Irrespeto</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Mal uso de la propiedad ajena</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Hablar de forma excesiva</Label>
                  </FormGroup>
                </Col>
                <Col  xs = "4">
                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Falta de tarea</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Falta del material de clase</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Falta de cooperación</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Descortés</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Uniforme inapropiado</Label>
                  </FormGroup>
                </Col>

                <Col xs = "4">
                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Comer en clase</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Inquieto, no presta atención</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Falta de reverencia</Label>
                  </FormGroup>

                  <FormGroup check className="mb-1">
                    <Input type="checkbox" />
                    <Label check>Mal comportamiento en los pasillos</Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <FormGroup>
                    <Input
                      id="otherReason"
                      name="otherReason"
                      placeholder="Otra razón"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col xs="6">Nombre del Padre o Tutor:</Col>
                <Col xs="6" className="text-right">
                  <Button color="primary">Save</Button>
                </Col>
              </Row>
            </Form>
          </td>
        </tr>
      
      </tbody>
    </Table>
  )
}

export default PrivilegeRemovalForm
