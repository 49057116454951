//const APIURL = process.env.REACT_APP_API_URL


const APIURL = "https://api.ccmbtareas.com/"
//api.ccmbtareas

export class MyUploadAdapter {
  constructor(loader) {
    // CKEditor 5's FileLoader instance.
    this.loader = loader

    // URL where to send files.
    this.url = `${APIURL}ckeditor/files`
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest()
      this._initListeners(resolve, reject)
      this._sendRequest()
    })
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())

    xhr.open("POST", this.url, true)
    xhr.responseType = "json"
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr
    const loader = this.loader

    const genericErrorText = `No se pudo cargar el archivo por favor verifica tu conexion`

    xhr.addEventListener("error", () => reject(genericErrorText))
    xhr.addEventListener("abort", () => reject())
    xhr.addEventListener("load", () => {
      const response = xhr.response

      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        )
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: `${APIURL}dist/files/ckeditor/${response.url}`,
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData()

    this.loader.file.then(result => {
      data.append("upload", result)

      this.xhr.send(data)
    })
  }
}
