import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Container, ButtonGroup,Button, Modal, ModalHeader, ModalBody ,Row,Col, FormGroup, Label,Input,ModalFooter } from "reactstrap"
import PrivilegeRemovalForm from 'components/Common/Notifications/PrivilegeRemovalForm'
import DataTable from "components/Common/DataTable/DataTable"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {onCreateStart,  onCreateCancel } from  "features/teacher/subject/behaviorTrack/behaviorTrackSlice"


// Create a memoized selector for specific Behavior properties

// Create a selector for the assignment state
 const selectBehaviorState = state => state.teacher.behaviorTrack


const selectBehaviorProperties = createSelector(
  [selectBehaviorState],
  behaviorState => ({
    isModal: behaviorState.isModal,
    mode: behaviorState.mode,
    isModalDelete: behaviorState.isModalDelete,
  })
)



const BehaviorTrack = (props) => {
     const { t, subject } = useOutletContext()

const dispatch = useDispatch()
const behaviorState = useSelector(selectBehaviorProperties)
 const { isModal, mode, isModalDelete } = behaviorState

 const handlePrivilegeRemovalCreateStart = () => {
   dispatch(onCreateStart())
 }

 const handleCancel=()=>{ 
    dispatch(onCreateCancel())
 }
 const handleCancelDelete=()=>{}

const handleConfirmToDelete=()=>{}
// const {
//   handlePageChange,
//   handleSearchTerm,
//   getRow,
//   handlePrivilegeRemovalCreateStart,
//   handleCancelDelete,
//   handleConfirmToDelete,
//   handleCancel,
//   privilegeState,
// } = props



// const {
//   isModal,
//   isLoading,
//   dataTable,
//   pagination,
//   searchTerm,
//   isModalDelete,
//   mode,
// } = privilegeState



  return (
    <Container className='pt-4'>
      <Row>
        <Col xs="4">
          <div className="text-center">
            <Button className="text-center btn-lg" color="success"
            
            >
              + {t("Exhortation Note")}
            </Button>
          </div>
        </Col>
        <Col xs="4">

        

          <div className="text-center">
          <Button
            type="button"
            color="success"
            className="text-center btn-lg"
            onClick={() => handlePrivilegeRemovalCreateStart()}
          >
            <i className="text-center btn-lg"></i>
            {t("Privilege Removal")}
          </Button>
          </div>
        </Col>
        <Col xs="4">
          <div className="text-center">
            <Button className="text-center btn-lg" color="success">
              + {t("Uniform Color Code")}
            </Button>
          </div>
        </Col>
      </Row>


      <Row>
        {/* <Col>
          <DataTable
            data={dataTable}
            isLoading={isLoading}
            //customLoader={<TableSkeleton/>}
            paginationState={pagination}
            onPageChange={handlePageChange}
            getRow={getRow}
          />
        </Col> */}
      </Row>

      <Modal
        size={"xl"}
        isOpen={isModal}
        toggle={handleCancel}
        autoFocus={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={handleCancel}> {t(mode)}</ModalHeader>
        <ModalBody>
          <PrivilegeRemovalForm {...props} />
        </ModalBody>
      </Modal>

      <Modal
        size={"s"}
        isOpen={isModalDelete}
        toggle={handleCancelDelete}
        autoFocus={true}
        backdrop={"static"}
      >
        <ModalHeader toggle={handleCancelDelete}>{t("Delete")}</ModalHeader>
        <ModalBody>
          <p>
            {t(
              "Do you really want to delete these records? This process cannot be undone."
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              handleConfirmToDelete()
            }}
          >
            {t("Delete")}
          </Button>
          <Button color="secondary" onClick={handleCancelDelete}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>

    </Container>
  )
}

export default BehaviorTrack