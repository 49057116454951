import { takeLatest, put, call,all } from "redux-saga/effects"
import {
  onReadData,
  onReadDataSuccess,
  onCreateIsSubmitting,
  onCreateSuccess,
  uploadProgress,
  setFieldError,
  onDelete,
  onDeleteSuccess,
  onEditStart,
  onEditIni,
  onEditIsSubmitting,
  onEditSuccess,
  onFecthIndicators,
  onFecthIndicatorsSuccess,
} from "./assignmentSlice"
import { parseISO, format } from "date-fns"
import { apiClient } from "api/axios"
import { generateOptions } from "helpers/select_helper"
import { Fields } from "./fieldSchema"
import { parseAndFormatDateTime } from "helpers/time_helper"
import Moment from "moment"
import { take } from "lodash"
import { toast } from "react-toastify"
import {
  gradeEndpoints,
  subjectEndpoints,
  competenceEndpoints,
  assignmentEndpoints,
} from "api/endpoints"
function* workerFetchAssignments({ payload }) {
  try {
    const subject = payload.subject
    const pageNumber = payload.pageNumber
    const search = payload.searchTerm

    const [response, periods, submissionTypes, activities,competences,specificCompetences] = yield all([
      call(apiClient.get,subjectEndpoints.assignments(subject?.id,`page=${pageNumber}&search=${search}`)),
      call(apiClient.get, gradeEndpoints.periods(subject?.grade_id)),
      call(apiClient.get, `api/submission-types/all`),
      call(apiClient.get,  subjectEndpoints.actvities(subject?.id)),
      call(apiClient.get, subjectEndpoints.competences(subject?.id)),
      call(apiClient.get, subjectEndpoints.specific_competences(subject?.id)),
    ])

    const periodsOptions = generateOptions(
      periods?.data,
      "id",
      element => element.name
    )
    const submissionTypesOptions = generateOptions(
      submissionTypes?.data,
      "id",
      element => element.name
    )
    const activityOptions = generateOptions(
      activities?.data,
      "id",
      element => element.name
    )
    const competencesOptions = generateOptions(competences?.data, "id", element => element.name)
   // const specificCompetencesOptions = generateOptions(specificCompetences?.data, "id", element => element.name)
    const specificCompetencesOptions =specificCompetences?.data

 const structuredData = specificCompetencesOptions.map(item => ({
   label: item.fundamental_competence_name,
   options: [
     {
       label: item.name,
       value: item.id,
     },
   ],
 }))

    yield put(
      onReadDataSuccess({
        response: response.data,
        periodsOptions,
        submissionTypesOptions,
        activityOptions,
        competencesOptions,
        specificCompetencesOptions:structuredData,
      })
    )
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}




function* workerCreateAssignment({ payload: { fields, subject } }) {
  try {
       
    const formData = new FormData()
    formData.append("title", fields.title.value)
    formData.append("description", fields.description.value)
    formData.append("subject_id", subject.id)
    formData.append("teacher_id", subject.teacher_id)
    formData.append("submission_type_id", fields.submission_type_id.value.value)
    formData.append("period_id", fields.period_id.value.value)
    formData.append("activity_id", fields.activity_id.value.value)
    formData.append("due_date",Moment(fields.due_date.value).format("YYYY-MM-DD H:mm:ss"))
    formData.append("publication_date",Moment(fields.publication_date.value).format("YYYY-MM-DD H:mm:ss"))
      formData.append(
        "specific_competence_id",
        fields.specific_competence_id.value.value
      )
      formData.append("indicator_id", fields.indicator_id.value.value)
    
    //formData.append("assignment_files", "value2")

    const response = yield call(
      apiClient.post,assignmentEndpoints.create,
    
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          console.log("Progress Event:", progressEvent)
        },
      }
    )

    yield put(onReadData({ subject, pageNumber: 1, searchTerm: "" }))
    const assignment = response.data.assignment
    yield put(onCreateSuccess({ assignment }))
    toast.success("Actividad creada exitosamente")
  } catch (error) {
    console.log(error +'120')
    if (error.isAxiosError) {
      const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
    }
  }
}

function* workerDelAssignment({ payload: { selectedTableRow } }) {
  const SubjectId = selectedTableRow.subject_id
  const assigmentId = selectedTableRow.id

  try {
   const response = yield call(apiClient.delete,assignmentEndpoints.delete(assigmentId));
   console.log(response)
  
     yield put(onDeleteSuccess({ id:assigmentId }))
   
  toast.success("Actividad eliminada exitosamente")

 } catch (error) {
  console.log(error)
  
 }

}


function* workerEditAssignmentStart({ payload: { selectedTableRow } }) {
  try {



    const { data } = yield call(apiClient.get,assignmentEndpoints.show(selectedTableRow.id))
    
    const updatedFields = {
      title: { ...Fields.title, value: data?.title },
      description: { ...Fields.description, value: data?.description },
      due_date: { ...Fields.due_date, value: new Date(data?.due_date) },
      activity_id: { ...Fields.activity_id, value: { value: data?.activity_id, label: data?.activity_name } },
        
      period_id: { ...Fields.period_id, value: { value: data?.period_id, label: data?.period_name } },
      submission_type_id: { ...Fields.submission_type_id, value: { value: data?.submission_type_id, label: data?.submission_type_name } },
      publication_date: { ...Fields.publication_date, value: new Date(data?.publication_date) },
      specific_competence_id: { ...Fields.specific_competence_id, value: { value: data?.specific_competence_id, label: data?.specific_competence_name } },
      indicator_id: { ...Fields.indicator_id, value: { value: data?.indicator_id, label: data?.indicator_name } },
      competence_id: { ...Fields.competence_id, value: { value:data?.competence_id, label:data?.competence_name } },


    };

    yield put(onEditIni({ fields: updatedFields }));
  } catch (error) {
    console.log(error);
  }
}


function* workerEditAssignment({ payload: { fields, subject,selectedTableRow } }) {
  try {
    

//Moment(value.value).format("YYYY-MM-DD H:mm:ss")
    const formData = new FormData()
    formData.append("id", selectedTableRow.id)
    formData.append("title", fields.title.value)
    formData.append("description", fields.description.value)
    formData.append("subject_id", subject.id)
    formData.append("teacher_id", subject.teacher_id)
    formData.append("submission_type_id", fields.submission_type_id.value.value)
    formData.append("period_id", fields.period_id.value.value)
    formData.append("activity_id", fields.activity_id.value.value)
    formData.append("due_date",Moment(fields.due_date.value).format("YYYY-MM-DD H:mm:ss"))
    formData.append("publication_date",Moment(fields.publication_date.value).format("YYYY-MM-DD H:mm:ss"))
      formData.append(
        "specific_competence_id",
        fields.specific_competence_id.value.value
      )
      formData.append("indicator_id", fields.indicator_id.value.value)
    

const { data }= yield call(
      apiClient.post,
      assignmentEndpoints.update(selectedTableRow.id),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          console.log("Progress Event:", progressEvent)
        },
      })
    toast.success("Actividad editada exitosamente") 
      yield put(onEditSuccess({ assignment: data.assignment }))
  } catch (error) {
    
  if (error.isAxiosError) {
      const { status, data } = error?.response
      const messages = data?.messages
      if (status === 400) {
        for (const fieldKey in messages) {
          if (messages.hasOwnProperty(fieldKey)) {
            yield put(setFieldError({ fieldKey, errors: [messages[fieldKey]] }))
          }
        }
      }
    }




  }
  





}
function* workerFetchIndicators({ payload: { competenceId } }) {

  try {
    const {data} = yield call(apiClient.get, competenceEndpoints.indicators(competenceId));
    const indicatorsOptions = generateOptions(
      data,
      "id",
      element => element.name
    )
   
    yield put(onFecthIndicatorsSuccess({ indicatorsOptions }));
  } catch (error) {
    console.log(error);
  }
}


export function* watchTeacherAssignments() {
  yield takeLatest(onReadData.type, workerFetchAssignments)
  yield takeLatest(onCreateIsSubmitting.type, workerCreateAssignment)
  yield takeLatest(onDelete.type,workerDelAssignment )
  yield takeLatest(onEditStart.type,workerEditAssignmentStart )
  yield takeLatest(onEditIsSubmitting.type,workerEditAssignment )
  yield takeLatest(onFecthIndicators.type,workerFetchIndicators )
  
}

export default watchTeacherAssignments
