// subjectSlice.js
import { createSlice } from "@reduxjs/toolkit"

const subjectSlice = createSlice({
  name: "teacher/subject",
  initialState: {
    isLoading: false,
    subject: null,
  },
  reducers: {
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.subject = action.payload
    },
    handleSelectSubject: (state, action) => {
      state.subject = action.payload
    },
    handleSelectSubjectChangeProperty: (state, action) => {
      const { fieldName, newValue } = action.payload
      state.subject[fieldName] = newValue
    },
  },
})

export const {
  onReadData,
  onReadDataSuccess,
  handleSelectSubject,
  handleSelectSubjectChangeProperty,
} = subjectSlice.actions
export default subjectSlice.reducer


export const selectCurrentSubject = state => state.teacher.subject.subject
export const selectCurrentIsloading = state => state.teacher.subject.isLoading
