// assignmentSlice.js
import { createSlice } from "@reduxjs/toolkit"
import {Fields} from "./fieldSchema";
import { TableSchema,data } from "./tableSchema";

const assignmentSlice = createSlice({
  name: "coordinator/assignment",
  initialState: {
    subjects: [],
    isLoading: false,
    isModal: false,
    activeTab: 1,
    fields: { ...Fields },
    isSubmitting: false,
    assignments: [],
    dataTable: { ...TableSchema },
    pagination: {
      total_pages: 0,
      current_page: 1,
      per_page: 10,
    },

    periodsOptions: [],
    submissionTypesOptions: [],
    activityOptions: [],
    competencesOptions: [],
    specificCompetencesOptions: [],
    indicatorsOptions: [],
    isIndicatorOptionsLoading: false,
    uploadPercentage: 0,
    searchTerm: "",
    isModalDelete: false,
    selectedTableRow: null,
    mode: null,
    isLoadingEdit: false,
    gradeOptions: [],
    subjectOptions: [],
    selectedSubject: null,
    isSubjectLoading: false,
    subject:null,
    selectedGrade:null,

  },
  reducers: {
    handleFieldChange: (state, action) => {
      const { fieldName, newValue } = action.payload

      state.fields[fieldName].value = newValue
      state.fields[fieldName].errors = []
    },

    uploadProgress: (state, action) => {
      state.uploadPercentage = action.payload
    },

    setActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    setFieldError: (state, action) => {
      const { fieldKey, errors } = action.payload
      state.fields[fieldKey].errors = errors
      state.isSubmitting = false
    },
    setValidationErrors: (state, action) => {
      state.fields = { ...action.payload.fieldErrors }
    },
    setPageNumber: (state, action) => {
      state.pagination.current_page = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setSelectSubject: (state, action) => {
      state.selectedSubject = action.payload.option
      state.subject = action.payload.subject
    },
    setSelectedGrade: (state, action) => {
      state.selectedSubject = null
      state.subjectOptions = []
      state.selectedGrade = action.payload
    },
    setSubjectOptions: (state, action) => { 
      state.subjectOptions = action.payload
    },
    onFecthIndicators: state => {
      state.isIndicatorOptionsLoading = true
    },
    onFecthIndicatorsSuccess: (state, action) => {
      state.isIndicatorOptionsLoading = false
      state.indicatorsOptions = action.payload.indicatorsOptions
    },
    /*___________________________________________________________region Create____________________________________________________________________________ */
    onCreateStart: state => {
      state.mode = "Create"
      state.isModal = true
    },
    onCreateCancel: state => {
      // use in edit and create

      state.mode = null
      state.isModal = false
      state.fields = { ...Fields }
      state.activeTab = 1
      state.uploadPercentage = 0
    },

    onCreateIsSubmitting: state => {
      state.isSubmitting = true
    },
    onCreateSuccess: state => {
      state.isSubmitting = false
      state.fields = { ...Fields }
      state.isModal = false
      state.activeTab = 1
      state.uploadPercentage = 0
    },
    //end of create
    /*___________________________________________________________region Read____________________________________________________________________________ */
    onReadData: state => {
      state.isLoading = true
    },
    onReadDataSuccess: (state, action) => {
      state.isLoading = false
      state.dataTable.rows = action.payload.response.assignments
      state.pagination = action.payload.response.pagination
      state.periodsOptions = action.payload.periodsOptions
      state.submissionTypesOptions = action.payload.submissionTypesOptions
      state.activityOptions = action.payload.activityOptions
      state.competencesOptions = action.payload.competencesOptions
      state.specificCompetencesOptions =
        action.payload.specificCompetencesOptions
    },
    onReadDataError: (state, action) => {},
    onReadSubjects: state => {
      state.isSubjectLoading = true


    },
    onReadSubjectsSuccess: (state, action) => {
      state.isSubjectLoading = false
      state.subjects = action.payload.subjects
      state.gradeOptions = action.payload.gradeOptions
    },


    /*______________________________________________________________region Edit______________________________________________________________________________ */
    onEditStart: (state, action) => {
      state.selectedTableRow = action.payload.selectedTableRow
      state.mode = "Edit"
      state.isModal = true
      state.isLoadingEdit = true
    },
    onEditIni: (state, action) => {
      state.isLoadingEdit = false
      state.fields = action.payload.fields
    },

    onEditIsSubmitting: (state, action) => {
      state.isSubmitting = true
    },
    onEditSuccess: (state, action) => {
      state.isSubmitting = false
      state.isModal = false
      state.mode = null
      state.fields = { ...Fields }
      state.activeTab = 1
      state.uploadPercentage = 0
      state.dataTable.rows = state.dataTable.rows.map(row => {
        if (row.id === action.payload.assignment.id) {
          return action.payload.assignment
        }
        return row
      })
    },

    /*___________________________________________________________region Delete____________________________________________________________________________ */

    onDeleteStart: (state, action) => {
      state.selectedTableRow = action.payload.selectedTableRow
      state.isModalDelete = true
    },
    onDeleteCancel: state => {
      state.isModalDelete = false
      state.selectedTableRow = null
    },
    onDelete: (state, action) => {},
    onDeleteSuccess: (state, action) => {
      const { id } = action.payload
      state.isModalDelete = false
      state.selectedTableRow = null
      state.dataTable.rows = state.dataTable.rows.filter(row => row.id !== id)
    },
    onDeleteError: (state, action) => {},
  },
})

export const {
  onReadData,
  onReadDataSuccess,
  setActiveTab,
  handleFieldChange,
  onCreateIsSubmitting,
  setValidationErrors,
  onCreateSuccess,
  uploadProgress,
  setFieldError,
  setPageNumber,
  setSearchTerm,
  onCreateStart,
  onCreateCancel,
  onDelete,
  onDeleteCancel,
  onDeleteStart,
  onDeleteSuccess,
  onEditStart,
  onEditIni,
  onEditIsSubmitting,
  onEditSuccess,
  onFecthIndicators,
  onFecthIndicatorsSuccess,
  onReadSubjects,
  onReadSubjectsSuccess,
  setSelectSubject,
  setSelectedGrade,
  setSubjectOptions,
} = assignmentSlice.actions
export default assignmentSlice.reducer

export const selectCurrentSubjects = state => state.teacher.assignment.subjects
export const selectCurrentIsloading = state => state.teacher.assignment.isLoading
