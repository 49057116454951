import { createSlice } from "@reduxjs/toolkit"

const behaviorTrackSlice = createSlice({
  name: "teacher/behaviorTrack",
  initialState: {
    isModal: false,
    mode: null,
    isModalDelete:false
  },
  reducers: {
    onCreateStart: state => {
      state.isModal = !state.isModal
      state.mode = "create"
    },

    onCreateCancel: state => {
      state.isModal = !state.isModal
      state.mode = null



    }
  },
})

export const { onCreateStart, onCreateCancel } = behaviorTrackSlice.actions
export default behaviorTrackSlice.reducer
