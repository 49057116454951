import { takeLatest, put, call } from "redux-saga/effects"
import { onReadData, onReadDataSuccess } from "./subjectSlice"

import { apiClient } from "api/axios"
function* fetchSubjectData(action) {
  try {
    const id = action.payload
    const response = yield call(apiClient.get, `api/teacher/subjects/${id}`)
    yield put(onReadDataSuccess(response.data))
  } catch (error) {
    console.log(error)
    // Handle errors, e.g., dispatch an error action
  }
}

export function* watchTeacherSubject() {
  yield takeLatest(onReadData.type, fetchSubjectData)
}

export default watchTeacherSubject
