import { takeLatest, put, call, all } from "redux-saga/effects"

import { apiClient } from "api/axios"
import { onReadData, onReadDataSuccess, onEdit } from "./schoolGradeSlice"
import { toast } from "react-toastify"
import { subjectEndpoints } from "api/endpoints"
function* workerFetchGradeBook({payload:{subjectId}}) {
    try {
        const response = yield call(apiClient.get, subjectEndpoints.gradebook(subjectId))

        yield put(onReadDataSuccess(response.data))
        console.log(response.data)

    } catch (error) {
        
        toast.error(error.message)
    }
}
function* workerEditGradeBook({payload:{subjectId,studentId,assignmentId,score}}) {
    try {
        const response = yield call(apiClient.post, subjectEndpoints.gradebookUpdate(subjectId),{subjectId,studentId,assignmentId,score})

        // yield put(onReadData(response.data))
        yield put(onReadDataSuccess(response.data))

    } catch (error) {
        
        toast.error(error.message)
    }


}

export function* watchSchoolGrade() {
   yield takeLatest(onReadData.type, workerFetchGradeBook)
   yield takeLatest(onEdit.type, workerEditGradeBook)

}

export default watchSchoolGrade
