
import { parse, format } from "date-fns"

// Reusable function to parse and format datetime strings
export const parseAndFormatDateTime = (dateTimeString, formatString) => {
  // Split the date and time
  const dateTimeParts = dateTimeString.split(" ")
  const datePart = dateTimeParts[0]
  const timePart = dateTimeParts[1].slice(0, 5) // Remove seconds (":ss")

  // Combine the date and time without seconds
  const dateTimeWithoutSeconds = `${datePart} ${timePart}`

  // Parse the datetime without seconds
  const parsedDate = parse(
    dateTimeWithoutSeconds,
    "yyyy-MM-dd HH:mm",
    new Date()
  )

  // Format the parsed date as needed
  return format(parsedDate, formatString)
}

export const filterPassedTime = time => {
  const currentDate = new Date()
  const selectedDate = new Date(time)
  return currentDate.getTime() < selectedDate.getTime()
}
