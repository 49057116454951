// Define a utility function to generate options for select inputs
//usage example 
// generateOptions(
//   subjects,
//   "subject_id",
//   element => `${element.subject_name} --> ${element.course_name}`
// )
export const generateOptions = (data, valueKey, labelCallback) => {
  const options = data.map(element => ({
    value: element[valueKey],
    label: labelCallback(element),
  }))
  // Return the array of option objects
  return options
}

export const extractUniqueOptions = (data, idKey, nameKey) => {
  const options = []
  const optionsMap = new Map()

  data.forEach(item => {
    const id = item[idKey]
    const name = item[nameKey]
    const key = `${id}-${name}`

    if (!optionsMap.has(key)) {
      optionsMap.set(key, true)
      options.push({ [idKey]: id, [nameKey]: name })
    }
  })

  return options
}