import React, { useEffect } from "react"
import {
  Outlet,
  useOutletContext,
  NavLink as RRNavLink,
  useParams,
} from "react-router-dom"
import { Row, Col, Nav, NavItem, NavLink,Card,CardBody } from "reactstrap"
import { selectCurrentSubject } from 'features/teacher/subject/subjectSlice'
import { useSelector, useDispatch } from "react-redux"
import { onReadData } from 'features/teacher/subject/subjectSlice'
import Breadcrumb from "components/Common/Breadcrumb"
const TeacherSujbect = () => {
const dispatch = useDispatch()
const { t } = useOutletContext()
const  {subject_id}= useParams()
const subject = useSelector(selectCurrentSubject)

 useEffect(() => {
   // Check if subject is null and make the API call if it is
   if (subject === null) {
   
     dispatch(onReadData(subject_id)) 
   }
 }, [subject, subject_id, dispatch])

 if (subject === null) {
   // You can optionally show a loading spinner or message here while the API call is in progress
   return <div>Loading...</div>
 }


  return (
    <>
      <div className="page-content">
        <Row className="checkout-tabs">
          <Col lg="2">
            <Nav className="flex-column" pills>
              <NavItem key={"tabs"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/start`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bxs-home d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Home")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs2"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/plannings`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-book-open d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Planning")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs3"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/activities`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-edit-alt d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Activities")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs4"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/attendance`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-badge-check d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("Attendance")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs5"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/school-grades`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i className={`bx bx-notepad d-block check-nav-icon`}></i>
                  <p className="font-weight-bold mb-3">{t("School grades")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs7"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/behavior-track`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i
                    className={`bx bx bx-food-menu d-block check-nav-icon`}
                  ></i>
                  <p className="font-weight-bold mb-3">{t("Behavior track")}</p>
                </NavLink>
              </NavItem>
              <NavItem key={"tabs6"}>
                <NavLink
                  tag={RRNavLink}
                  to={`/subjects/${subject_id}/virtual-classroom`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  <i
                    className={`bx bx-video-recording d-block check-nav-icon`}
                  ></i>
                  <p className="font-weight-bold mb-3">
                    {t("Virtual Classroom")}
                  </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          <Col lg="10">
            <Breadcrumb
              title={t(subject?.grade_name + " " + subject?.name)}
              breadcrumbItem={t("Dashboard")}
            />
            <Card className="h-100">
              <CardBody>
                <Outlet context={{ t, subject_id, subject }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TeacherSujbect