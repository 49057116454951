export const validator= eachEntry => {
  const fieldErrors = {}
  const validationErrorSummary = []

  for (const fieldName in eachEntry) {
    const { value, rules, label } = eachEntry[fieldName]
    const errors = []

    // Check for required field
    if (
      rules &&
      rules.require &&
      (value === null || (typeof value === "string" && value.trim() === ""))
    ) {
      const requiredError = `is required`
      errors.push(requiredError)
      validationErrorSummary.push(requiredError)
    }

    // Add more validation rules as needed
    if (
      rules &&
      rules.maxLength &&
      typeof value === "string" &&
      value.length > rules.maxLength
    ) {
      const maxLengthError = `exceeds maximum length of ${rules.maxLength} characters.`
      errors.push(maxLengthError)
      validationErrorSummary.push(maxLengthError)
    }

    // Check for errors in fields without rules
    if (!rules && value === "") {
      const error = `${label} has an error.`
      errors.push(error)
      validationErrorSummary.push(error)
    }

    // Always build the fieldError object, even if there are no errors
    fieldErrors[fieldName] = {
      ...eachEntry[fieldName],
      errors: errors,
    }
  }

  const hasErrors = Object.keys(fieldErrors).some(
    fieldName => fieldErrors[fieldName].errors.length > 0
  ) // Calculate the boolean

  return { fieldErrors, validationErrorSummary, hasErrors }
}

export const handleData = eachEntry => {
  const formData = new FormData()
  Object.entries(eachEntry).forEach(([key, value]) => {
    if (value.type === "text") {
      formData.append(key, value.value)
    } else if (value.type === "select") {
      if (value?.isMulti) {
        formData.append(key, JSON.stringify(value.value))
      } else {
        formData.append(key, value.value.value)
      }
    } else if (value.type === "date") {
      let date = new Date(value.value)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ")

      formData.append(key, date)
    } else if (value.type === "datetime") {
      let datetime = Moment(value.value).format("YYYY-MM-DD H:mm:ss")

      formData.append(key, datetime)
    } else if (value.type === "radio") {
      formData.append(key, value.value)
    } else if (value.type === "file") {
      value.value.length > 0 &&
        value.value.forEach(file => {
          formData.append(key, file)
        })
      // formData.append(key, value.value);
    }
  })
  return formData
}
