import React from 'react'
import { useOutletContext } from 'react-router-dom'


import { useSelector,useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { useEffect } from 'react'
import {
  onReadData,
  onEditStart,
  onEdit,
  onSaveConfirmationModalStart,
  onSaveConfirmationCancel,
  onFilterChange,
  onToggleColumnVisibility,
} from "features/teacher/subject/schoolGrade/schoolGradeSlice"
import Skeleton from 'react-loading-skeleton'
import SaveConfirmationModal from 'components/Common/SaveConfirmationModal'
import GradeBook from 'components/Common/GradeBook/GradeBook'


// Create a selector for the assignment state
const selectSchoolGradeState = state => state.teacher.schoolGrade

// Create a memoized selector for specific assignment properties
const selectSchoolGradeProperties = createSelector(
  [selectSchoolGradeState],
  schoolGradeState => ({
    dataTable: schoolGradeState.dataTable,
    filterby: schoolGradeState.filterby,
    isLoading: schoolGradeState.isLoading,
    selectedRow: schoolGradeState.selectedRow,
    isEditing: schoolGradeState.isEditing,
    isSaveConfirmationModal: schoolGradeState.isSaveConfirmationModal,
    visibleColumns: schoolGradeState.visibleColumns,
  })
)
const SchoolGrade = () => {
  const dispatch = useDispatch()
  const schoolGradeState = useSelector(selectSchoolGradeProperties)
  const { t, subject } = useOutletContext()

const handleDropdownHeader = (optionName, header) => {
console.log(optionName, header)

}
const handleRowUpdate = (studentId, assignmentId, score) => {

  dispatch(onEdit({subjectId:subject.id,studentId,assignmentId,score}))
}
const handleSelectedRow=(row)=>{
   dispatch(onEditStart(row))
 
}


const handleConfimationModalStart = (actualValue, nota, assignmentId) => {
  

dispatch(onSaveConfirmationModalStart({actualValue, nota, assignmentId}))


}

const handleSaveConfirmationModal =()=>{


}

const handleCancelConfirmationModal =()=>{
  dispatch(onSaveConfirmationCancel())
};

const handleFilter = (e) => {
  dispatch(onFilterChange(e.target.value))


}
const handleToggleColumnVisibility = columnId => {
  dispatch(onToggleColumnVisibility({ columnId }))
}
useEffect(() => {
  dispatch(onReadData({subjectId:subject.id}))
},[])



  return (
    <>
      {schoolGradeState.isLoading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <>
          <GradeBook
            schoolGradeState={schoolGradeState}
            handleDropdownHeader={handleDropdownHeader}
            handleRowUpdate={handleRowUpdate}
            handleSelectedRow={handleSelectedRow}
            handleConfimationModalStart={handleConfimationModalStart}
            handleFilter={handleFilter}
            handleToggleColumnVisibility={handleToggleColumnVisibility}
            t={t}
            editable={true}
          />
          {/* isOpen, handleSave,handleCancel, t = key => key */}
          <SaveConfirmationModal
            isOpen={schoolGradeState.isSaveConfirmationModal}
            handleSave={handleSaveConfirmationModal}
            handleCancel={handleCancelConfirmationModal}
            t={t}
          />
        </>
      )}
    </>
  )
}

export default SchoolGrade