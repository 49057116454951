import React from 'react'
import { useOutletContext } from "react-router-dom"




const Planning = () => {
const {t} = useOutletContext() 

  return (
    <div>
      {t("Planning")}


    </div>

  )
}

export default Planning